import { HrOrganizationStructureItem } from "@/types/hr/organizationStructure/hrOrganizationStructureItem";
import { ApiHrOrganizationStructure } from "@/api/hr/types/organizationStructure/apiHrOrganizationStructure";

export const flatOrganizationStructure = (structure: ApiHrOrganizationStructure): HrOrganizationStructureItem[] => {
	const data = [structure];
	const employeeStructures = structure.employeeStructures;
	const stack = data.map((item, index) => ({ ...item, level: 1 }));
	const result: HrOrganizationStructureItem[] = [];
	
	while (stack.length) {
		const item = stack.shift()!;
		
		if(item.departments) {
			stack.unshift(...item.departments.map((child: any) => {
				const hasSupervisor = employeeStructures.some(x => x.id === child.supervisor?.id);
				const parent = hasSupervisor ? child.supervisor.id : item.id;
				
				return { ...child, level: item.level + (hasSupervisor ? 2 : 1), parent };
			}));
		}
		
		result.push(item);
	}
	
	return result;
};