import { registerAlertService } from "@/store/modules/alerts/services/alertService";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree } from "vuex";
import alertsModule from "@/store/modules/alerts";
import userModule from "@/store/hr/modules/user";
import employeesModule from "@/store/hr/modules/employees";
import tasksModule from "@/store/hr/modules/tasks";
import employeeModule from "@/store/hr/modules/employee";
import RootState from "@/store/hr/types/rootState";
import { PageModeType } from "@/store/hr/types/pageModeType";
import { mutationTypes, actionTypes } from "@/store/hr/types";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import vacationPlanEmployeesModule from "@/store/hr/modules/vacationPlanEmployees";
import vacationPlanCalendarModule from "@/store/hr/modules/vacationPlanCalendar";
import vacationPlansModule from "@/store/hr/modules/vacationPlans";
import hrVacationApplicationsModule from "@/store/hr/modules/vacationApplications";
import personnelDocumentsModule from "@/store/hr/modules/personnelDocuments";
import hrVacationApplicationModule from "@/store/hr/modules/vacationApplication";
import hrOrganizationStructureModule from "@/store/hr/modules/organizationStructure";

Vue.use(Vuex);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{
	[actionTypes.handleServerError]({ commit }, ex: Error) {
		switch (ex.constructor) {
			case AccessForbiddenException:
				commit(mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED);
				break;
			default:
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
				break;
		}
	}
};

const mutations = <MutationTree<RootState>>{
	[mutationTypes.SET_IS_APP_LOADING](state, value) {
		state.isAppLoading = value;
	},
	[mutationTypes.SET_PAGE_MODE](state, value) {
		state.pageMode = value;
	},
	[mutationTypes.RESET_PAGE_MODE](state) {
		state.pageMode = PageModeType.OK;
	},
	[mutationTypes.SET_PAGE_MODE_NOT_FOUND](state) {
		state.pageMode = PageModeType.PAGE_NOT_FOUND;
	},
	[mutationTypes.SET_PAGE_MODE_ACCESS_FORBIDDEN](state) {
		state.pageMode = PageModeType.ACCESS_DENIED;
	}
};

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	modules: {
		[userModule.namespace]: {
			...userModule
		},
		[employeesModule.namespace]: {
			...employeesModule
		},
		[employeeModule.namespace]: {
			...employeeModule
		},
		[vacationPlansModule.namespace]: {
			...vacationPlansModule
		},
		[vacationPlanEmployeesModule.namespace]: {
			...vacationPlanEmployeesModule
		},
		[vacationPlanCalendarModule.namespace]: {
			...vacationPlanCalendarModule
		},
		[hrVacationApplicationsModule.namespace]: {
			...hrVacationApplicationsModule
		},
		[hrVacationApplicationModule.namespace]: {
			...hrVacationApplicationModule
		},
		[alertsModule.namespace]: {
			...alertsModule
		},
		[tasksModule.namespace]: {
			...tasksModule
		},
		[personnelDocumentsModule.namespace]: {
			...personnelDocumentsModule
		},
		[hrOrganizationStructureModule.namespace]: {
			...hrOrganizationStructureModule
		}
	}
});

const registerSubscribers = (store: any) => {
	employeesModule.initializeSubscribersManager(store);
	vacationPlansModule.initializeSubscribersManager(store);
	vacationPlanEmployeesModule.initializeSubscribersManager(store);
	vacationPlanCalendarModule.initializeSubscribersManager(store);
	tasksModule.initializeSubscribersManager(store);
	hrVacationApplicationsModule.initializeSubscribersManager(store);
};
registerAlertService(store);
registerSubscribers(store);

export default store;
