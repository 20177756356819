import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls from "@/api/config/urls";
import { ApiHrOrganizationStructure } from "@/api/hr/types/organizationStructure/apiHrOrganizationStructure";

export class HrOrganizationStructureController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getOrganizationStructure = async () => {
		return await this.client.get<ApiHrOrganizationStructure>(urls.hr.employee.getOrganizationStructure);
	};
}
