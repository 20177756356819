import IPageState from "@/store/shared/base/types/pageState";
import { HrOrganizationStructureItem } from "@/types/hr/organizationStructure/hrOrganizationStructureItem";

export default class OrganizationStructureState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public items: HrOrganizationStructureItem[] = [],
		public openedSubstructureParentDepartmentId?: string,
	)
	{
	}
}
