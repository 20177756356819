import { ApiHrOrganizationStructure } from "@/api/hr/types/organizationStructure/apiHrOrganizationStructure";
import { flatOrganizationStructure } from "@/store/hr/helpers/flatOrganizationStructure";
import * as primitives from "basicprimitives/src";
import { formatFirstLastName } from "@/utils/formatting";
import { HrOrganizationStructureItem } from "@/types/hr/organizationStructure/hrOrganizationStructureItem";

export const mapOrganizationStructure = (structure: ApiHrOrganizationStructure): HrOrganizationStructureItem[] => {
	const flatten = flatOrganizationStructure(structure);
	
	const mapped = flatten.map((x, i) => {
		const head = x.head ?? x.supervisor;
		const parent = flatten.find(y => y.id === x.parent);
		const parentHead = parent?.head ?? parent?.supervisor ?? x.supervisor;
		
		return {
			...x,
			childrenPlacementType: i && x.level < 3 ? primitives.ChildrenPlacementType.Vertical : primitives.ChildrenPlacementType.Horizontal,
			templateName: `level-${x.level}`,
			head: x.head ? {
				...x.head,
				name: formatFirstLastName(x.head),
				department: x.head.position,
				headName: parentHead && formatFirstLastName(parentHead)
			} : undefined,
			employees: (x.employees ?? []).map(y => ({
				...y,
				name: formatFirstLastName(y),
				headName: head && formatFirstLastName(head),
				department: x.title
			}))
		};
	});
	const mappedSupervisors = structure.employeeStructures.map(x => ({
		id: x.id,
		type: x.type,
		title: x.position,
		parent: structure.id,
		head: ({ ...x, name: formatFirstLastName(x), department: x.position, headName: formatFirstLastName(structure.head) }),
		level: 2,
		templateName: "level-2",
		childrenPlacementType: primitives.ChildrenPlacementType.Vertical,
	}));
	
	return [...mappedSupervisors, ...mapped];
};